const constants = {
    appName: "Lingala",
    companyName: "TSG Smart Technologies",
    email: "tsgstbusiness@gmail.com",
    verbTypes: new Set(["v. a.", "v. n.", "v. i.", "v.", "verb"]),
    languages: {
        FRENCH: "fr",
        ENGLISH: "en"
    },
    colors: {
        darkGreen: "#344955ff",
        textGreen: "#008080",
        gray: "#D3D3D3",
        darkGray: "#3f3f3f"
    },
    prices: {
        1: '2.00',
        3: '5.00',
        6: '10.00',
        12: '20.00'
    },
    wordTypes: {
        "v. n.": "verb",
        "v. a.": "verb",
        "v. i.": "verb",
        "verb": "verb",
        "v.": "verb",
        "conj.": "conjunction",
        "n.": "noun",
        "adj.": "adjective",
        "adv.": "adverb",
        "pron.": "pronoun",
        "prep.": "preposition",
        "intj.": "interjection",
        "art.": "article"
    }
};

export {constants}