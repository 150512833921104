import React from 'react';
import {getLanguage, t} from "react-switch-lang";
import {useData} from "../services/DataContext";
import frFlag from "../assets/images/fr-flag.svg";
import enFlag from "../assets/images/en-flag.svg";

const LanguageSwitch = () => {

    const {onChangeLanguage} = useData();

    return (
        <div className="languageSwitch align-items-center"
             onClick={onChangeLanguage(getLanguage() === "fr" ? "en" : "fr")}>
            <div>
                <img style={{width: 30, height: 30}} src={getLanguage() === "fr" ? frFlag : enFlag}
                     alt={getLanguage() === "fr" ? "french flag" : "english flag"}/>&nbsp;
                <small style={{color: "white"}}>{t("languageChange")}</small>
            </div>
        </div>
    );
};

export default LanguageSwitch;