import React, {useContext, useEffect, useState} from "react"

import {symmetricDecryption} from "./Utilities"
import http from "./httpService";
import {getLanguage, setLanguage} from "react-switch-lang";
import {constants} from "../Constants";

const DataContext = React.createContext()

export function useData() {
    return useContext(DataContext)
}

export function DataProvider({children}) {

    const [loading, setLoading] = useState(false);

    const [words, setWords] = useState([]);
    const [verbs, setVerbs] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    class Word {
        constructor({
                        ln, en, fr, synonyms = [], plurals = [], meaning, wordType, wordFSId
                        // , slang = [], langila = []
                    }
        ) {
            this.wordFSId = wordFSId;
            this.ln = ln;
            this.en = en;
            this.fr = fr;
            this.synonyms = synonyms;
            this.plurals = plurals;
            this.wordType = wordType;
            this.meaning = meaning;
            // this.slang = slang;
            // this.langila = langila;
        }
    }

    // useEffect is the equivalent to componentDidMound and componentDidiUpdate
    useEffect(() => {

        // Firestore Database
        // db.collection("words").onSnapshot((snapshot) => setWords(snapshot.docs.map((doc) => doc.data())));

        // Real Time Database
        // fecth just once


        //
        // dbFB.ref('words').once('value', (snapshot) => {
        //
        //     let data = [];
        //
        //     snapshot.forEach((childSnapshot) => {
        //         // const childKey = childSnapshot.key;
        //         const childData = new Word(childSnapshot.val());
        //         data.push(childData);
        //
        //     });
        //
        //     setWords(data);
        //
        // });

        const loadWords = async () => {

            setLoading(true);

            let unsubscribe = await http.post(process.env.REACT_APP_API_BASE_URL + "words/" + "getAllWords", {}, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST',
                        'authorization': process.env.REACT_APP_TOKEN,
                    },
                }
            ).then((value) => {
                    let data = [];

                    Object.values(JSON.parse(symmetricDecryption(value.data))).forEach((word) => data.push(new Word(word)));
                    setWords(data);

                    handleVerbs(data);

                }
            ).finally(() => {
                setLoading(false);
            });
            return unsubscribe;

        }

        loadWords();

        document.title = "Lingala";
    }, []);

    const onChangeLanguage = (key) => () => {
        setLanguage(key);
        handleVerbs(words);
    };

    const handleSearchQuery = (query) => {
        setSearchQuery(query);
    };

    const handleVerbs = (words) => {
        let verbValues = words.filter((word) => constants.verbTypes.has(word.wordType));

        if (getLanguage() === constants.languages.FRENCH) {
            // Removing duplicated french match

            verbValues = [...new Map(verbValues.map((verb) => [verb.fr, verb])).values()];

            // sorting verbValues based on french match
            verbValues.sort((a, b) => a.fr.localeCompare(b.fr));
            // verbValues.sort(function (a, b) {
            //   return a.fr.localeCompare(b.fr);
            // });
        } else {
            // Removing duplicated english match
            verbValues = [...new Map(verbValues.map((verb) => [verb.en, verb])).values()];

            // sorting verbValues based on english match
            verbValues.sort((a, b) => a.en.localeCompare(b.en));
            // verbs.sort(function (a, b) {
            //   return a.en.localeCompare(b.en);
            // });
        }
        setVerbs(verbValues);
    }


    const handleWords = () => {

        let wordValues = [];

        if (getLanguage() === constants.languages.FRENCH) {
            // REMOVING DUPLICATE BASED ON SAME WORD AND MATCH / WORD AND MATCHFR    nyama+anima / tonga+build
            wordValues = [...new Map(words.map((word) => [word.ln + word.fr + word.wordType, word])).values()];

            // sorting words based on french match
            words.sort((a, b) => a.fr.localeCompare(b.fr));
        } else {
            // REMOVING DUPLICATE BASED ON SAME WORD AND MATCH / WORD AND MATCHFR    nyama+anima / tonga+build
            wordValues = [...new Map(words.map((word) => [word.ln + word.en + word.wordType, word])).values()];

            // sorting words based on english match
            words.sort((a, b) => a.en.localeCompare(b.en));
        }

        return wordValues;
    };


    useEffect(() => {

        // const unsubscribe =
        //
        //     auth.onDataStateChanged(user => {
        //
        //         setCurrentUser(user)
        //         setLoading(false)
        //
        //         if (user) {
        //             getUserTransactions(user.uid);
        //         } else {
        //         }
        //
        //     })
        //
        // return unsubscribe
    }, [])

    const value = {
        loading,
        words, setWords,
        verbs, setVerbs,
        handleVerbs,
        handleWords,
        searchQuery, setSearchQuery,
        onChangeLanguage,
        handleSearchQuery
    }

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    )
}
