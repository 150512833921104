import React, {useEffect, useState} from "react";
import Word from "../components/Word";
import SearchBox from "../components/SearchBox";
// import FlipMove from "react-flip-move"
import {getLanguage, t, translate} from "react-switch-lang";
import illustration from "../assets/images/PeopleTalkingIllustration.svg";
// import background from "../images/background.svg"
import {Helmet} from 'react-helmet';
import {useAuth} from './../services/AuthContext';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {googleProvider} from "../services/Firebase";
import {accentRemover, getWordType, levenshtein} from '../services/Utilities';
import {useData} from "../services/DataContext";
import LanguageSwitch from "../components/LanguageSwitch";
import {constants} from "../Constants";
import AdHorizontal from "../components/AdHorizontal";

function Dictionary() {

    const {words, verbs, onChangeLanguage, handleSearchQuery, searchQuery, handleWords} = useData();
    const [exactMatch, setExactMatch] = useState([])
    const [startsWithMatch, setStartsWithMatch] = useState([])
    const [containMatch, setContainMatch] = useState([])
    const [suggestionsMatch, setSuggestionsMatch] = useState([])
    const [currentSearchQuery, setCurrentSearchQuery] = useState(searchQuery.toLowerCase());

    const {currentUser, socialMediaAuth} = useAuth();
    const [loading, setLoading] = useState(false);

    const handleSignIn = async () => {

        try {
            setLoading(true)
            const res = await socialMediaAuth(googleProvider);

            // console.log(res);

        } catch (error) {

            // console.log(error)

        }

        setLoading(false)

    }


    const pleaseLogin = () => toast(<div>
            {t("login")}
            <button className="btn btn-primary ms-3" type="button" onClick={() => handleSignIn()}>{t("here")}</button>
        </div>
    );

    useEffect(() => {

        if (currentSearchQuery && currentSearchQuery !== "") {
            handleSearch(currentSearchQuery);
        }
    }, [])


    let wordList = handleWords();


    const handleSearch = (query) => {

        setCurrentSearchQuery((!query) ? query : query.toLowerCase());

        if (query && query !== "") {

            let wordResultSet = new Set([]);
            let data = filterResults(query.toLowerCase(), wordList, wordResultSet);
            let filterExactMatch = data.filterExactMatch;
            let filteredStartsWithMatch = data.filteredStartsWithMatch;
            let filteredContainMatch = data.filteredContainMatch;

            if (query.startsWith("ko ") && query.length > 3) {
                data = filterResults(query.substring(3), verbs, wordResultSet);
                filterExactMatch = [...filterExactMatch, ...data.filterExactMatch];
                filteredStartsWithMatch = [...filteredStartsWithMatch, ...data.filteredStartsWithMatch];
                filteredContainMatch = [...filteredContainMatch, ...data.filteredContainMatch];
            } else if (query.startsWith("ko") && query.length > 2) {
                data = filterResults(query.substring(2), verbs, wordResultSet);
                filterExactMatch = [...filterExactMatch, ...data.filterExactMatch];
                filteredStartsWithMatch = [...filteredStartsWithMatch, ...data.filteredStartsWithMatch];
                filteredContainMatch = [...filteredContainMatch, ...data.filteredContainMatch];
            }

            setExactMatch(filterExactMatch);
            setStartsWithMatch(filteredStartsWithMatch);
            setContainMatch(filteredContainMatch);


            //////////////////////////////SUGGESTIONS//////////////////////////////////////////////////

            let suggestions = new Map();
            let id = 0;

            wordList.forEach(word => {

                if (!wordResultSet.has(word)) {

                    let distance = levenshtein(accentRemover(word.ln), query);
                    if (distance !== 0) { // Do not include the exact match
                        suggestions.set(word.ln, {id: id++, term: word.ln, distance: distance});
                    }

                    word.plurals.forEach(plural => {
                        distance = levenshtein(accentRemover(plural), query);
                        if (distance !== 0) { // Do not include the exact match
                            suggestions.set(plural, {id: id++, term: plural, distance: distance});
                        }
                    });

                    word.synonyms.forEach(synonym => {
                        distance = levenshtein(accentRemover(synonym), query);
                        if (distance !== 0) { // Do not include the exact match
                            suggestions.set(synonym, {id: id++, term: synonym, distance: distance});
                        }

                    });
                }

            });

            let closestMatchToReturn = [...suggestions.values()];
            closestMatchToReturn.sort((a, b) => a.distance - b.distance);
            closestMatchToReturn = [...closestMatchToReturn.slice(0, 25)];
            setSuggestionsMatch(closestMatchToReturn);

        } else {
            setExactMatch([]);
            setStartsWithMatch([]);
            setContainMatch([]);
            setSuggestionsMatch([]);
        }


    };


    const filterResults = (currentSearchTerm, thisWordList, myWordsSet) => {

        const collator = new Intl.Collator("fr", {ignorePunctuation: true, sensitivity: "base"});
        let filterExactMatch = [];
        let filteredStartsWithMatch = [];
        let filteredContainMatch = [];

        filterExactMatch = thisWordList.filter(
            (word) => collator.compare(word.ln, currentSearchTerm) === 0
                || (getLanguage() === constants.languages.FRENCH ? collator.compare(word.fr, currentSearchTerm) === 0 : collator.compare(word.en, currentSearchTerm) === 0)
                || (word.plurals.some(function (plural) {
                    return collator.compare(plural, currentSearchTerm) === 0;
                }))
                || (word.synonyms.some(function (synonym) {
                    return collator.compare(synonym, currentSearchTerm) === 0;
                }))
        );

        myWordsSet = new Set([...myWordsSet, ...filterExactMatch]);


        /////////////////////////STARTSWITH/////////////////////////////////////////////////
        let startQuery = accentRemover(currentSearchTerm) + " ";

        filteredStartsWithMatch = thisWordList.filter(
            (word) => (currentSearchTerm.length > 3
                && !myWordsSet.has(word) &&
                (accentRemover(word.ln).startsWith(startQuery)
                    || (getLanguage() === constants.languages.FRENCH ? accentRemover(word.fr).startsWith(startQuery) : accentRemover(word.en).startsWith(startQuery))
                    || word.plurals.some((plural) => {
                        return accentRemover(plural).startsWith(startQuery);
                    })
                    || word.synonyms.some((synonym) => {
                        return accentRemover(synonym).startsWith(startQuery);
                    })

                ))
        );
        myWordsSet = new Set([...myWordsSet, ...filteredStartsWithMatch]);


        ///////////////////////////INCLUDES/////////////////////////////////////////////////
        let noAccentSearchQuery = accentRemover(currentSearchTerm);
        startQuery = noAccentSearchQuery + " ";

        filteredContainMatch = thisWordList.filter(
            (word) =>
                (currentSearchTerm.length > 3 &&
                    !myWordsSet.has(word) &&
                    (accentRemover(word.ln).includes(noAccentSearchQuery)
                        || (getLanguage() === constants.languages.FRENCH ? accentRemover(word.fr).includes(noAccentSearchQuery) : accentRemover(word.en).includes(noAccentSearchQuery))
                        || word.plurals.some((plural) => {
                            return accentRemover(plural).includes(noAccentSearchQuery);
                        })
                        || word.synonyms.some((synonym) => {
                            return accentRemover(synonym).includes(noAccentSearchQuery);
                        })

                    )
                )
        );

        myWordsSet = new Set([...myWordsSet, ...filteredContainMatch]);

        return {
            myWordsSetValue: myWordsSet,
            filterExactMatch: filterExactMatch,
            filteredStartsWithMatch: filteredStartsWithMatch,
            filteredContainMatch: filteredContainMatch
        };

    }


    return (
        <div>

            <ToastContainer


                position={toast.POSITION.BOTTOM_CENTER}
                // autoClose={3000}
                // hideProgressBar
                // newestOnTop={false}
                // closeOnClick
                // rtl={false}
                // pauseOnFocusLoss
                // draggable
                // pauseOnHover

            />

            <AdHorizontal/>
            <br/>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("dictionary")}</title>
                <meta name="description"
                      content={t("dictionaryDescription")}/>

                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>

            <div className="row align-items-center mb-2">

                <div className="col-lg-9 col-md-8 col-sm-12 mb-2">
                    <SearchBox value={currentSearchQuery} onChange={handleSearch}/>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
                    <LanguageSwitch/>
                </div>
            </div>


            {(exactMatch.length === 0 && startsWithMatch.length === 0 && suggestionsMatch.length === 0) &&
                <div style={{borderRadius: '10px', overflow: 'hidden'}}>
                    <img style={{
                        maxWidth: 400,
                        maxHeight: 400,
                        display: "block",
                        marginRight: "auto",
                        marginLeft: "auto", borderRadius: "20px"
                    }} src={illustration} alt="illustration"/></div>}


            {exactMatch.length !== 0 && <ul className="list-group">
                {exactMatch.map((word) => (
                    <Word key={word.wordFSId} word={word} pleaseLogin={pleaseLogin}
                          wordType={getWordType(word.wordType)} searchQuery={currentSearchQuery}
                          handleSearchQuery={handleSearchQuery}/>
                ))}
            </ul>}


            {startsWithMatch.length !== 0 &&
                <div><h6 className="fw-bold my-4">{t("startsWith")}</h6>

                    <ul className="list-group"> {startsWithMatch.map((word) => (
                        <Word key={word.wordFSId} word={word} pleaseLogin={pleaseLogin}
                              wordType={getWordType(word.wordType)} searchQuery={currentSearchQuery}
                              handleSearchQuery={handleSearchQuery}/>
                    ))}
                    </ul>
                </div>}


            {containMatch.length !== 0 && <div><h6 className="fw-bold my-4">{t("seeAlso")}</h6>
                <ul className="list-group">   {containMatch.map((word) => (
                    <Word key={word.wordFSId} word={word} pleaseLogin={pleaseLogin}
                          wordType={getWordType(word.wordType)} searchQuery={currentSearchQuery}
                          handleSearchQuery={handleSearchQuery}/>
                ))}
                </ul>
            </div>}

            {
                suggestionsMatch.length !== 0 && <div>
                    <h6 className="fw-bold my-4">{t("suggestions")}</h6>

                    <div style={{display: 'flex', flexWrap: "wrap"}}>
                        {suggestionsMatch.map((word) => (<h5 key={word.id} className="suggestion me-4"><a
                                onClick={() => {
                                    handleSearch(word.term);
                                    handleSearchQuery(word.term);
                                }}
                            >
                                <ins>{word.term}</ins>
                            </a></h5>
                        ))}

                    </div>
                    <br/>
                </div>
            }

        </div>
    );

}

export default translate(Dictionary);
