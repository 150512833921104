import React, {useEffect, useState} from 'react'

import {t, translate} from "react-switch-lang";
import PayPal from '../components/PayPal';
import {useAuth} from '../services/AuthContext';
import Delayed from '../components/Delayed';
import {getPlanPrice, getStrToPrettyDateFormat, hasValidSubscription} from "../services/Utilities"
import PlayButton from './../components/PlayButton';
import {Link} from "react-router-dom";
import {constants} from "../Constants";


function Account() {

    const {currentUser, userTransaction} = useAuth();
    const [isSubscribed, setIsSubscribed] = useState(false)
    const firstName = currentUser.displayName.split(' ')[0];
    const [months, setMonths] = useState(1);

    useEffect(() => {
        if (userTransaction) {
            setIsSubscribed(hasValidSubscription(userTransaction.subscriptionExpirationDate))
        }
    }, [userTransaction]);

    const suscribed = () => {
        return (
            <div>
                {
                    userTransaction &&

                    <table className="table caption-top table-sm">
                        <thead>
                        <tr>
                            <th scope="col">{t("duration")}</th>
                            <th scope="col">{t("price")}</th>
                            <th scope="col">{t("expirationDate")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th> {userTransaction.subscriptionDuration}</th>
                            <td>$ {userTransaction.subscriptionPrice}</td>
                            <td>{getStrToPrettyDateFormat(userTransaction.subscriptionExpirationDate)}</td>

                        </tr>

                        </tbody>
                    </table>

                }


            </div>

        );
    }

    const onSetMonths = (thisDuration) => {
        setMonths(thisDuration);
    }


    const notSuscribed = () => {
        return (
            <div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="w-100" style={{maxWidth: "400px"}}>

                        <div className="card align-items-center justify-content-center" style={{padding: 10}}>

                            <h5> {t("audioTest")}</h5>

                            {t("audioTestMessage")}
                            <br/>
                            <br/>
                            <PlayButton audioTest={true}/>

                        </div>
                    </div>

                </div>


                <div className="d-flex align-items-center justify-content-center">


                    <div className="row">

                        {Object.keys(constants.prices).map((thisDuration, index) => <div
                            className="col-lg-3 col-md-6 col-sm-12 my-2"
                            key={index}><PlanCard
                            thisDuration={thisDuration}
                            onSetMonths={onSetMonths}/>

                        </div>)}

                    </div>


                </div>
            </div>

        );
    }


    return (
        <div>

            <h5 className="mb-4">{t("subscription")}</h5>


            <Delayed>
                {isSubscribed ? suscribed() : notSuscribed()}

                <br/>

                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title fs-5">{months} {t("monthsSubscription")} </h6>

                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <PayPal duration={months}/>
                                <br/>
                                <Link to="/terms-and-conditions"><small data-bs-dismiss="modal">Terms and
                                    Conditions</small></Link>

                            </div>

                        </div>
                    </div>
                </div>

            </Delayed>

        </div>
    )
}


const PlanCard = ({thisDuration, onSetMonths}) => {

    return (

        <div className="card" style={{padding: 20}}>

            <strong className="mb-2">{thisDuration} {t("monthsSubscription")}</strong>

            <button type="button" className="btn btn-success mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal"
                    onClick={() => {
                        onSetMonths(thisDuration)
                        // setPaypal(true)
                    }
                    }>{t("subscribe")}
            </button>


            <small>{t("packageIncluded")}</small>

            <small className="mb-2" dangerouslySetInnerHTML={{__html: t("packageDescription")}}/>

            <small>$ {getPlanPrice(thisDuration)}</small>


        </div>);

}

export default translate(Account);
