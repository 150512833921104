import React, {useContext, useEffect, useState} from "react"
import {auth, db} from "./Firebase"

import {hasValidSubscription} from "./Utilities"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [subscribed, setSubscribed] = useState(false)
    const [userTransaction, setUserTransaction] = useState(null)

    async function signup(email, password) {
        return await auth.createUserWithEmailAndPassword(email, password)
    }

    async function login(email, password) {
        return await auth.signInWithEmailAndPassword(email, password)
    }

    async function logout() {
        return await auth.signOut()
    }

    async function resetPassword(email) {
        return await auth.sendPasswordResetEmail(email)
    }

    async function updateEmail(email) {
        return await currentUser.updateEmail(email)
    }

    async function updatePassword(password) {
        return await currentUser.updatePassword(password)
    }

    async function socialMediaAuth(provider) {


        provider.setCustomParameters({
            'login_hint': 'user@example.com',
        });
        // // return auth.signInWithPopup(provider)
        return await auth.signInWithPopup(provider).then((res) => {

            handleSignIn(res);

        }).catch((e) => {
            // if(e.code == 'auth/popup-blocked')
            // {

            //
            // let result =  auth.signInWithRedirect(provider);
            //
            // console.log(result);


            // .then((res) => {
            //
            //         handleSignIn (res);
            //     });
            // }
        });

    }

    function handleSignIn(res) {

        let user;

        if (res.additionalUserInfo.isNewUser) { // is called only if the user logs in for the first time

            user = {
                name: res.user.displayName,
                email: res.user.email,
                joinedOn: new Date().toISOString(),
                deviceId: window.navigator.appVersion,
                subscriptionDocumentId: '',
                subscriptionPrice: '0.00',
                subscriptionDuration: 0,
                subscriptionExpirationDate: new Date(2000, 0, 1).toISOString(),
                // subscriptionExpirationDate: "01-01-2020 01:01:01",
                subscriptionPaymentDate: new Date(2000, 0, 1).toISOString(),
            }


        } else {
            user = {deviceId: window.navigator.appVersion};
        }

        savingUserTransactions(res.user.uid, user);
    }

    async function savingUserTransactions(userId, user) {

        await db.collection("users").doc(userId).set(user, {merge: true}).then(async (docRef) => {


            // console.log("DOCUMENT SET")

        }).catch((error) => {
            console.error("Error adding document: ", error);
        });


    }

    // async function singleDeviceCheck(deviceId) {
    //
    //     if (deviceId !== window.navigator.appVersion) {
    //         try {
    //             await logout()
    //         } catch (error) {
    //             // // console.log(error)
    //         }
    //     }
    // }

    async function getUserTransactions(userId) {
        await db.collection('users').doc(userId).onSnapshot(async snapshot => {

                try {
                    let data = snapshot.data();

                    // console.log(data.deviceId, window.navigator.appVersion, " OUTSIDE")
                    if (data.deviceId && data.deviceId !== window.navigator.appVersion) {
                        try {

                            // console.log(data.deviceId, window.navigator.appVersion, " INSIDE")
                            await logout()
                        } catch (error) {

                            // console.log(error)
                        }
                    } else {
                        setUserTransaction(data);
                        setSubscribed(hasValidSubscription(data.subscriptionExpirationDate))
                    }

                } catch (error) {

                    // console.log(error)

                }

            },
            error => {
                // // console.log(error)

                // console.clear();
            }
        );

    }


    useEffect(() => {

        const unsubscribe =

            auth.onAuthStateChanged(user => {

                setCurrentUser(user)
                setLoading(false)

                if (user) {
                    getUserTransactions(user.uid);
                } else {
                }

            })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        userTransaction,
        subscribed,
        login,
        socialMediaAuth,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        getUserTransactions,
        savingUserTransactions
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
