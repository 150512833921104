import React from "react";

import {t, translate} from "react-switch-lang";
import {useData} from "../services/DataContext";

const SearchBox = ({value, onChange}) => {
    const {words} = useData()

    return <input type="text" name="query" autoComplete="off" spellCheck="false" className="form-control"
                  disabled={!words || words.length === 0}
                  placeholder={t("translate")} value={value} onChange={(e) => onChange(e.currentTarget.value)}/>;
};

export default translate(SearchBox);
