import React, {useLayoutEffect} from "react"

export default function AdHorizontal() {

    useLayoutEffect(() => {


        (window.adsbygoogle = window.adsbygoogle || []).push({});
        return () => {

        };
    }, [])


    return (
        <ins className="adsbygoogle"
             style={{display: 'block'}}
             data-ad-client="ca-pub-9197339027000425"
             data-ad-slot="1416984525"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>
    );

}
