import React from "react";
import {getLanguage, setLanguage, t, translate} from "react-switch-lang";
import frFlag from "../assets/images/fr-flag.svg";
import enFlag from "../assets/images/en-flag.svg";
import {useLocation} from "react-router-dom";
import NotFound from './NotFound';
import {useData} from "../services/DataContext";
import LanguageSwitch from "../components/LanguageSwitch";


function Conjugation() {


    const {verbs} = useData();


    const location = useLocation();
    let verb = location.pathname.toLowerCase();
    verb = verb.replace("/conjugation/", "");

    let wordType;

    if (location.state) {
        wordType = location.state.wordType;

        return <div> {conjugationResult(verb, wordType)}</div>;
    } else {
        wordType = "verb";
        return searchForVerb(verbs, verb, wordType);
    }


}

export default translate(Conjugation);

function searchForVerb(verbs, verb, wordType) {
    let found = verbs.find((word) => word.ln === verb || word.synonyms.includes(verb));

    if (found) {
        return <div> {conjugationResult(verb, wordType)}</div>;
    } else {
        return <NotFound/>;
    }

}

function conjugationResult(verb, wordType) {
    let originalVerb = "";
    let trimmedVerb = "";

    let rest = "";

    originalVerb = verb;
    trimmedVerb = originalVerb;

    let whiteSpace = originalVerb.indexOf(" ");

    if (whiteSpace !== -1) {
        rest = originalVerb.substring(whiteSpace + 1);
        trimmedVerb = originalVerb.substring(0, whiteSpace);
    }

    let centerContent = {textAlign: "center"};
    let tenseSyle = {marginBottom: 30, textAlign: "center"};


    let flag = getLanguage() === "fr" ? <img style={{width: 50, height: 50}} src={frFlag} alt="french flag"/> :
        <img style={{width: 50, height: 50}} src={enFlag} alt="english flag"/>;


    const handleChangeLanguage = (key) => () => {
        setLanguage(key);
    };


    let imperative;

    if (!(trimmedVerb.endsWith("er") || trimmedVerb.endsWith("re") || wordType === "v. i.")) {

        imperative = (<div><h3>{t("imperative")}</h3>
            {/* <div style={{ border: "solid grey" }}> */}

            <div className="p-2">
                <h5 className="card-subtitle mb-2 text-muted" style={centerContent}>
                    {trimmedVerb.endsWith("e") ? trimmedVerb.substring(0, trimmedVerb.length - 1) + "a" : trimmedVerb + " " + rest}
                    {/* Mikendele ==> Mikendela */}
                </h5>
            </div>


            <br></br>
            <br></br>
            <br></br>
            {/* </div> */}

        </div>);
    }

    return (
        <div className="container">

            <LanguageSwitch/>
            <br/>

            <h1 style={{textAlign: "center"}}><small>ko</small> {verb}</h1>

            <div><h3>{t("indicative")}</h3>
                <div className="row">

                    <div className="col-lg-3 col-sm-6 border p-2">
                        <h5 style={tenseSyle}>{t("simplePresent")}</h5>

                        {simplePresent(trimmedVerb, rest, wordType).map((v) => (
                            <h6 key={v.toString()} className="card-subtitle mb-2 text-muted" style={centerContent}>
                                {v}
                            </h6>
                        ))}
                    </div>

                    <div className="col-lg-3 col-sm-6 border p-2">
                        <h5 style={tenseSyle}>{t("presentProgressive")}</h5>

                        {presentProgressive(trimmedVerb, rest, wordType).map((v) => (
                            <h6 key={v.toString()} className="card-subtitle mb-2 text-muted" style={centerContent}>
                                {v}
                            </h6>
                        ))}

                        <h4 className="card-subtitle mb-2 text-muted" style={centerContent}>
                            {t("or")}
                        </h4>

                        {presentProgressiveShort(trimmedVerb, rest, wordType).map((v) => (
                            <h6 key={v.toString()} className="card-subtitle mb-2 text-muted" style={centerContent}>
                                {v}
                            </h6>
                        ))}
                    </div>

                    <div className="col-lg-3 col-sm-6 border p-2">
                        <h5 style={tenseSyle}>{t("future")}</h5>

                        {future(trimmedVerb, rest, wordType).map((v) => (
                            <h6 key={v.toString()} className="card-subtitle mb-2 text-muted" style={centerContent}>
                                {v}
                            </h6>
                        ))}
                    </div>

                    <div className="col-lg-3 col-sm-6 border p-2">
                        <h5 style={tenseSyle}>{t("futurePerfect")}</h5>

                        {futurePerfect(trimmedVerb, rest, wordType).map((v) => (
                            <h6 key={v.toString()} className="card-subtitle mb-2 text-muted" style={centerContent}>
                                {v}
                            </h6>
                        ))}
                    </div>

                    <div className="col-lg-3 col-sm-6 border p-2">
                        <h5 style={tenseSyle}> {t("presentPerfect")}</h5>

                        {presentPerfect(trimmedVerb, rest, wordType).map((v) => (
                            <h6 key={v.toString()} className="card-subtitle mb-2 text-muted" style={centerContent}>
                                {v}
                            </h6>
                        ))}
                    </div>

                    <div className="col-lg-3 col-sm-6 border p-2">
                        <h5 style={tenseSyle}> {t("pastContinuous")}</h5>

                        {pastContinuous(trimmedVerb, rest, wordType).map((v) => (
                            <h6 key={v.toString()} className="card-subtitle mb-2 text-muted" style={centerContent}>
                                {v}
                            </h6>
                        ))}
                        <h4 className="card-subtitle mb-2 text-muted" style={centerContent}>
                            {t("or")}
                        </h4>

                        {pastContinuousOther(trimmedVerb, rest, wordType).map((v) => (
                            <h6 key={v.toString()} className="card-subtitle mb-2 text-muted" style={centerContent}>
                                {v}
                            </h6>
                        ))}
                    </div>

                    <div className="col-lg-3 col-sm-6 border p-2">
                        <h5 style={tenseSyle}>
                            {t("simplePast")} <br/> {t("and")} <br/> {t("pastPerfect")}
                        </h5>

                        {simplePastAndPastPerfect(trimmedVerb, rest, wordType).map((v) => (
                            <h6 key={v.toString()} className="card-subtitle mb-2 text-muted" style={centerContent}>
                                {v}
                            </h6>
                        ))}
                    </div>
                </div>

            </div>

            <br></br>

            {imperative}
        </div>
    );
}

function simplePresent(verb, rest, wordType) {
    let content = [];
    let ending = "";

    if (verb.endsWith("er") || verb.endsWith("re")) {
        // calculer or vivre
        verb = verb.substring(0, verb.length - 2);
        ending = "aka";
    } else if (verb.charAt(verb.length - 1) === "e" || verb.charAt(verb.length - 1) === "o") {
        //milengele or  different from longo, tondo
        verb = verb.substring(0, verb.length - 1);
        ending = "aka";
    } else {
        ending = "ka";
    }


    if (wordType !== "v. i.") {
        content.push(`Na ${verb}${ending} ${rest}`);
        content.push(`O ${verb}${ending} ${rest}`);
        content.push(`A ${verb}${ending} ${rest}`);
        content.push(`E ${verb}${ending} ${rest}`);
        content.push(`To ${verb}${ending} ${rest}`);
        content.push(`Bo ${verb}${ending} ${rest}`);
        content.push(`Ba ${verb}${ending} ${rest}`);
    } else {
        content.push(`E ${verb}${ending} ${rest}`);
    }


    return content;
}

function presentProgressive(verb, rest, wordType) {
    let content = [];
    let ko = " ko ";

    if (verb === "zala") {
        verb = "";
        ko = "";
    }

    if (wordType !== "v. i.") {

        content.push(`Na zali${ko}${verb} ${rest}`);
        content.push(`O zali${ko}${verb} ${rest}`);
        content.push(`A zali${ko}${verb} ${rest}`);
        content.push(`E zali${ko}${verb} ${rest}`);
        content.push(`To zali${ko}${verb} ${rest}`);
        content.push(`Bo zali${ko}${verb} ${rest}`);
        content.push(`Ba zali${ko}${verb} ${rest}`);
    } else {
        content.push(`E zali${ko}${verb} ${rest}`);
    }

    return content;
}

function presentProgressiveShort(verb, rest, wordType) {
    let content = [];
    let zo = "zo";
    let short = "o";

    if (verb === "zala") {
        verb = "";
        zo = "za";
        short = "a";
    }


    if (wordType !== "v. i.") {


        content.push(`Na‿${short} / Na‿${zo} ${verb} ${rest}`);
        content.push(`O‿${short} / O‿${zo} ${verb} ${rest}`);
        content.push(`A‿${short} / A‿${zo} ${verb} ${rest}`);
        content.push(`E‿${short} / E‿${zo} ${verb} ${rest}`);
        content.push(`To‿${short} / To‿${zo} ${verb} ${rest}`);
        content.push(`Bo‿${short} / Bo‿${zo} ${verb} ${rest}`);
        content.push(`Ba‿${short} / Ba‿${zo} ${verb} ${rest}`);

    } else {
        content.push(`E‿${short} / E‿${zo} ${verb} ${rest}`);
    }

    return content;
}

function future(verb, rest, wordType) {
    let content = [];

    if (wordType !== "v. i.") {
        content.push(`Na ko ${verb} ${rest}`);
        content.push(`O ko ${verb} ${rest}`);
        content.push(`A ko ${verb} ${rest}`);
        content.push(`E ko ${verb} ${rest}`);
        content.push(`To ko ${verb} ${rest}`);
        content.push(`Bo ko ${verb} ${rest}`);
        content.push(`Ba ko ${verb} ${rest}`);
    } else {
        content.push(`E ko ${verb} ${rest}`);
    }

    return content;
}

function futurePerfect(verb, rest, wordType) {
    let content = [];


    if (wordType !== "v. i.") {
        content.push(`Na lingaki na ${verb} ${rest}`);
        content.push(`O lingaki o ${verb} ${rest}`);
        content.push(`A lingaki a ${verb} ${rest}`);
        content.push(`E lingaki e ${verb} ${rest}`);
        content.push(`To lingaki to ${verb} ${rest}`);
        content.push(`Bo lingaki bo ${verb} ${rest}`);
        content.push(`Ba lingaki ba ${verb} ${rest}`);
    } else {
        content.push(`E lingaki e ${verb} ${rest}`);
    }

    return content;
}

function simplePastAndPastPerfect(verb, rest, wordType) {
    let content = [];

    if (verb.endsWith("er") || verb.endsWith("re")) {
        // calculer or vivre
        verb = verb.substring(0, verb.length - 2) + "a";
    } else if (verb.charAt(verb.length - 1) === "e" || verb.charAt(verb.length - 1) === "o") {
        // milengele or longo, tondo
        verb = verb.substring(0, verb.length - 1) + "a";
    }


    if (wordType !== "v. i.") {
        content.push(`Na ${verb}ki ${rest}`);
        content.push(`O ${verb}ki ${rest}`);
        content.push(`A ${verb}ki ${rest}`);
        content.push(`E ${verb}ki ${rest}`);
        content.push(`To ${verb}ki ${rest}`);
        content.push(`Bo ${verb}ki ${rest}`);
        content.push(`Ba ${verb}ki ${rest}`);
    } else {
        content.push(`E ${verb}ki ${rest}`);
    }

    return content;
}

function pastContinuous(verb, rest, wordType) {
    let content = [];
    let ko = " ko ";

    if (verb === "zala") {
        verb = "";
        ko = "";
    }

    if (wordType !== "v. i.") {
        content.push(`Na zalaki${ko}${verb} ${rest}`);
        content.push(`O zalaki${ko}${verb} ${rest}`);
        content.push(`A zalaki${ko}${verb} ${rest}`);
        content.push(`E zalaki${ko}${verb} ${rest}`);
        content.push(`To zalaki${ko}${verb} ${rest}`);
        content.push(`Bo zalaki${ko}${verb} ${rest}`);
        content.push(`Ba zalaki${ko}${verb} ${rest}`);
    } else {
        content.push(`E zalaki${ko}${verb} ${rest}`);
    }

    return content;
}

function pastContinuousOther(verb, rest, wordType) {
    let content = [];
    let ko = " ko ";

    if (verb === "zala") {
        verb = "";
        ko = "";
    }

    if (wordType !== "v. i.") {
        content.push(`Na bandaki${ko}${verb} ${rest}`);
        content.push(`O bandaki${ko}${verb} ${rest}`);
        content.push(`A bandaki${ko}${verb} ${rest}`);
        content.push(`E bandaki${ko}${verb} ${rest}`);
        content.push(`To bandaki${ko}${verb} ${rest}`);
        content.push(`Bo bandaki${ko}${verb} ${rest}`);
        content.push(`Ba bandaki${ko}${verb} ${rest}`);
    } else {
        content.push(`E bandaki${ko}${verb} ${rest}`);
    }

    return content;
}

function presentPerfect(verb, rest, wordType) {
    let ending = "";
    let content = [];

    if (verb === "kende") {
        verb = "key";
        ending = "i";
    } else if (verb.charAt(verb.length - 1) === "e" || verb.charAt(verb.length - 1) === "o") {
        // milengele or longo, tondo
        verb = verb.substring(0, verb.length - 1) + "i";
    } else if (verb.endsWith("wa") || verb.endsWith("sha")) {
        // tsha
        if (verb === "swa" || verb === "zwa") {
            ending = "i";
            verb = verb.substring(0, verb.length - 1);
        } else {
            ending = "e";
            verb = verb.substring(0, verb.length - 1);
        }
    } else if (verb.endsWith("de")) {
        ending = "yi";
        verb = verb.substring(0, verb.length - 3);
    } else if (verb.endsWith("ya") || verb.endsWith("ia")) // kweya
    {
        if (verb === "ya" || verb === "tiya") // tiya or ya
        {
            ending = "yé";
        } else // kweya
        {
            ending = "yi";
        }

        verb = verb.substring(0, verb.length - 2);
    } else if (verb.charAt(verb.length - 1) === "a") {
        ending = "i";
        verb = verb.substring(0, verb.length - 1);

        if (verb.charAt(verb.length - 1) === "i") {
            //yikinia
            ending = "";
        }
    }

    if (wordType !== "v. i.") {
        content.push(`Na ${verb}${ending} ${rest}`);
        content.push(`O ${verb}${ending} ${rest}`);
        content.push(`A ${verb}${ending} ${rest}`);
        content.push(`E ${verb}${ending} ${rest}`);
        content.push(`To ${verb}${ending} ${rest}`);
        content.push(`Bo ${verb}${ending} ${rest}`);
        content.push(`Ba ${verb}${ending} ${rest}`);
    } else {
        content.push(`E ${verb}${ending} ${rest}`);
    }

    return content;
}
