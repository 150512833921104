import React from "react";
import "./styles/App.css";
import NavBar from "./components/NavBar";
import Dictionary from "./screens/Dictionary";
import Verbs from "./screens/Verbs";
import Conjugation from "./screens/Conjugation";
import NotFound from "./screens/NotFound";
import {Redirect, Route, Switch} from "react-router-dom";
import Download from "./screens/Download";
import Footer from "./components/Footer";
import PropTypes from "prop-types";

import {setDefaultLanguage, setLanguageCookie, setTranslations, translate} from "react-switch-lang";
import en from "./assets/languages/en.json";
import fr from "./assets/languages/fr.json";
import PrivateRoute from "./components/PrivateRoute";
import Account from "./screens/Account";
import TermsAndConditions from "./screens/TermsAndConditions";
import {getName} from "./services/Utilities";
import {useAuth} from "./services/AuthContext";


// Do this two lines only when setting up the application
setTranslations({en, fr});
setDefaultLanguage("en");

// If you want to remember selected language

setLanguageCookie("language", {path: "/", maxAge: 157680000}, undefined);

function App() {

    const {currentUser} = useAuth();

    return (
        <div className="page-container">

            <NavBar/>


            {/*<Progress/>*/}

            <div className="container wrapped-content mt-3">

                {currentUser &&
                    <h6 className="text-sm-end mb-2">Mbote <span aria-label="greetings"
                                                                 role="img">🙌  </span> {getName(currentUser?.displayName)}
                    </h6>}

                <Switch>

                    <Route path="/dictionary" exact
                           component={() => <Dictionary/>}/>
                    <Route path="/verbs" exact
                           component={() => <Verbs/>}/>
                    <Route path="/download" exact component={Download}/>
                    <Route path="/conjugation" render={() => <Conjugation/>}/>
                    <PrivateRoute path="/account" component={Account}/>
                    <Route path="/terms-and-conditions" component={TermsAndConditions}/>
                    <Route path="/not-found" component={NotFound}/>
                    <Redirect from="/" to="/dictionary"/>
                    <Redirect to="/not-found"/>

                </Switch>

            </div>


            <Footer/>

        </div>
    );
}

App.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate(App);
