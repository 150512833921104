import React from "react";
import {Link} from "react-router-dom";
import {constants} from "../Constants";

function Footer() {

    const currentYear = new Date().getFullYear();

    const email = constants.email;
    const emailLink = `mailto:${email}`;

    return (
        <footer className="footer">
            <small className="text-muted">{constants.companyName} {currentYear}</small>
            <br/>
            <a href={emailLink}> <small>{email}</small></a>
            <br/>
            <Link to="/terms-and-conditions"><small>Terms and Conditions</small></Link>


        </footer>
    );
}

export default Footer;
