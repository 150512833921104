import React, {useState} from "react";
import "../styles/NavBar.css";
import {Link, useHistory} from "react-router-dom";
import {t, translate} from "react-switch-lang";
import {googleProvider} from "../services/Firebase";
import {useAuth} from "../services/AuthContext";
import {useData} from "../services/DataContext";
import {constants} from "../Constants";

function NavBar() {

    // Authentication
    const {currentUser, socialMediaAuth, logout} = useAuth()
    const {handleSearchQuery} = useData();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const isPaymentEnabled = JSON.parse(process.env.REACT_APP_IS_PAYMENT_ENABLED);

    const handleSignIn = async () => {

        try {
            setLoading(true)
            const res = await socialMediaAuth(googleProvider);

            // console.log(res);

        } catch (error) {

            // // console.log(error)
        }

        setLoading(false)

    }

    const handleLogout = async () => {

        try {
            await logout()
        } catch (error) {
            // // console.log(error)
        }
    }

    return (

        <nav className="navbar navbar-expand-lg" data-bs-theme="dark"
             style={{backgroundColor: constants.colors.darkGreen}}>
            <div className="container-fluid">
                <a className="navbar-brand logoStyle" href="/">Lingala</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse show navbar-collapse" id="navbarSupportedContent">

                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: "/dictionary"}} onClick={() => {
                                handleSearchQuery("")
                            }}>{t("dictionary")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: "/verbs"}}>{t("verbs")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to={{pathname: "/download"}}>{t("download")}</Link>
                        </li>

                        {currentUser && <li className="nav-item">
                            <Link className="nav-link" to={{pathname: "/account"}}>{t("account")}</Link>
                        </li>}
                    </ul>

                    <div className="d-flex">

                        {currentUser
                            &&
                            < Link className="nav-item nav-link" to="/account">
                                <img className="me-3" style={{width: 37, height: 37, borderRadius: 50}}
                                     src={currentUser.photoURL}
                                     alt="user avatar"/>
                            </ Link>
                        }
                        &nbsp;


                        {isPaymentEnabled && <span style={{cursor: "pointer"}} className="navbar-text" onClick={() => {

                            // console.log(currentUser)
                            if (currentUser) {
                                handleLogout();
                            } else {
                                handleSignIn();
                            }

                        }

                        }>
                                {currentUser ? t("signOut") : t("signIn")}</span>}
                    </div>
                </div>
            </div>
        </nav>
    );

}

export default translate(NavBar);
