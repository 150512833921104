import React from "react";
import {useHistory} from "react-router-dom";
import {getLanguage} from "react-switch-lang";

function Verb({verb, handleSearchQuery}) {
    // let language;
    let thisVerb;
    const history = useHistory();

    if (getLanguage() === "fr") {
        // language =  new French(verb.wordType);
        thisVerb = verb.fr;
    } else {
        // language = new English(verb.wordType);
        thisVerb = verb.en;
    }

    return (

        <li className="list-group-item" style={{cursor: "pointer"}} onClick={() => {
            handleSearchQuery(thisVerb);
            history.push("/dictionary")
        }}>
            <span style={{color: "grey"}}>{thisVerb}</span>
        </li>
    );
}


export default Verb;