import moment from "moment";
import {storage} from "./Firebase";
import {createDecipheriv,} from "crypto";
import {constants} from "../Constants";


const getWordType = (wordType) => {
    return constants.wordTypes[wordType];
}

const getPlanPrice = (month) => {
    return constants.prices[month];
}

const wordFormatToFile = (term, meaning, wordType) => {

    term = term.replaceAll(" ", "_");

    if (meaning !== "") {
        meaning = meaning.replaceAll(" ", "_");

        term = term + "+" + meaning;
    }

    if (wordType === "verb") {
        term = "ko_" + term;
    }

    return term;
}


const getAudioUrl = (term, meaning) => {

    term = wordFormatToFile(term, meaning);
    try {

        storage.ref("word_audio_files").child(term + ".mp3").getDownloadURL()
            .then((url) => {


                return url;
            });
    } catch (error) {

    }


}

const hasValidSubscription = (expirationDate) => {

    // Moment changed their format to YYYY-MM-DD for time comparison while hour database saves the date in the MM-DD-YYYY HH:mm:ss format
    try {

        // if (expirationDate !== undefined) {
        //
        //     let currentDate = moment().format("YYYY-MM-DD");
        //     expirationDate = moment(expirationDate, "MM-DD-YYYY HH:mm:ss").format("YYYY-MM-DD") // YYYY-MM-DD
        //     console.log(expirationDate);
        //     console.log(currentDate);
        //     return moment(expirationDate).isAfter(currentDate); // if true, Valid subscription
        // }

        if (expirationDate) {
            const currentDate = new Date();
            return new Date(expirationDate).getTime() > currentDate.getTime(); // if true, Valid subscription
        }


    } catch (error) {
        // console.log(error);
        return false;
    }

    return false;
}

const getStrToDateFormat = (str) => {
    try {
        return moment(str, "MM-DD-YYYY HH:mm:ss").format();
    } catch (error) {
        // console.log(error);
    }

}

const getStrToPrettyDateFormat = (str) => {

    try {
        return new Date(str).toLocaleString();
        // moment.locale();
        // return moment(str, "MM-DD-YYYY HH:mm:ss").format('LLL');
    } catch (error) {
        // console.log(error);
    }

}

const symmetricDecryption = (cipher) => {

    const decipher = createDecipheriv('aes256', "1d9f710b5a730c79ec4bcbc074791973", "48c65f366e1e98c6");
    const decryptedData = decipher.update(cipher, 'hex', 'utf-8') + decipher.final('utf-8');
    return decryptedData;
}

const accentRemover = (word) => {
    return word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const levenshtein = (a, b) => {
    var t = [], u, i, j, m = a.length, n = b.length;
    if (!m) {
        return n;
    }
    if (!n) {
        return m;
    }
    for (j = 0; j <= n; j++) {
        t[j] = j;
    }
    for (i = 1; i <= m; i++) {
        for (u = [i], j = 1; j <= n; j++) {
            u[j] = a[i - 1] === b[j - 1] ? t[j - 1] : Math.min(t[j - 1], t[j], u[j - 1]) + 1;
        }
        t = u;
    }
    return u[n];
}

const getName = (name) => {
    try {
        if (name && name !== "") {
            return name.split(' ')[0];
        }
    } catch (error) {
        return "";
    }
    return "";
}


export {
    getName,
    wordFormatToFile,
    getAudioUrl,
    hasValidSubscription,
    getWordType,
    getPlanPrice,
    getStrToDateFormat,
    getStrToPrettyDateFormat,
    symmetricDecryption,
    levenshtein,
    accentRemover
};
