import React from "react";
import Verb from "../components/Verb";
import {t} from "react-switch-lang";
import {Helmet} from 'react-helmet';
import {useData} from "../services/DataContext";
import LanguageSwitch from "../components/LanguageSwitch";
import AdHorizontal from "../components/AdHorizontal";

function Verbs() {

    const {onChangeLanguage, verbs, handleSearchQuery} = useData();

    // verbs = verbs.filter((verb) => verb.wordType === "v. a." || verb.wordType === "v. n." || verb.wordType === "verb");


    // verbs = [...new Map(verbs.map(verb=> [verb.verb, verb])).values()];
    //
    // verbs.sort(function (a, b) {
    //     return a.verb.localeCompare(b.verb);
    // });

    return (
        <div>
            <AdHorizontal/>
            <br/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("verbs")}</title>
                <meta name="description"
                      content={t("verbsDescription")}/>

                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>

            <LanguageSwitch/>


            <br/>
            <br/>

            {verbs && <ul className="list-group">
                {verbs.map((verb) => (
                    <Verb key={verb.wordFSId} verb={verb} handleSearchQuery={handleSearchQuery}/>
                ))}
            </ul>}


            <br/>
            <br/>


        </div>
    );
}

export default Verbs;
