import React, {useState} from 'react';
import {db} from '../services/Firebase';
import {useAuth} from "../services/AuthContext";
import {PayPalButton} from "react-paypal-button-v2";
import {getPlanPrice} from '../services/Utilities';
import {t, translate} from "react-switch-lang";

function PayPal({duration}) {


    const [paidFor, setPaidFor] = useState(false);
    const {currentUser, savingUserTransactions} = useAuth();

    const savingTransaction = async (orderData) => {

        let today = new Date();
        let expirationDate = new Date(today.setMonth(today.getMonth() + duration)).toISOString();

        let paymentDate = new Date().toISOString();
        orderData.subscriptionExpirationDate = expirationDate;
        orderData.subscriptionPaymentDate = paymentDate
        orderData.duration = duration;

        let userId = currentUser.multiFactor.user.uid;

        await db.collection("users").doc(userId).collection("paypal_checkout_sessions").add(orderData)
            .then(async (docRef) => {

                savingUserTransactions(userId,
                    {
                        subscriptionDocumentId: docRef.id,
                        subscriptionPrice: getPlanPrice(duration),
                        subscriptionDuration: duration,
                        subscriptionExpirationDate: expirationDate,
                        subscriptionPaymentDate: paymentDate
                    }
                )
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                    });

            })

        //         .catch((error) => {
        //             console.error("Error adding document: ", error);
        //         });
    }

    return (
        <div>
            {
                paidFor
                    ?

                    <div className="alert alert-success" role="alert">
                        <h4 className="alert-heading">{t("paymentReceived")}!!! <span aria-label="succesful transaction"
                                                                                      role="img"> 🎉🎉🎉</span></h4>
                        <p>{t("paymentThanks")}</p>
                        <hr/>
                    </div>

                    :
                    <PayPalButton

                        options={{
                            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                            disableFunding: "credit",
                            enableFunding: "venmo",
                        }}
                        style={
                            {
                                shape: 'rect',
                                color: 'gold',
                                layout: 'vertical',
                                label: 'pay'
                            }

                        }
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [{
                                    amount: {
                                        currency_code: "USD",
                                        value: getPlanPrice(duration)
                                    }
                                }],
                            });
                        }}
                        onApprove={(data, actions) => {
                            // Capture the funds from the transaction
                            return actions.order.capture().then(function (details) {

                                savingTransaction(details)
                                setPaidFor(true);

                            });
                        }}
                        // onnError={(err) => {
                        //     console.log("Paypal Time Out");
                        // }}
                    />
            }

        </div>
    )
}


export default translate(PayPal);