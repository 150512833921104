import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlayCircle, faVolumeUp} from '@fortawesome/free-solid-svg-icons'
import {hasValidSubscription, wordFormatToFile} from "../services/Utilities"
import {useHistory} from 'react-router-dom';
import {useAuth} from './../services/AuthContext';
import testAudio from "../assets/audios/yekola_lingala.mp3";
import 'react-toastify/dist/ReactToastify.css';
import {storage} from "../services/Firebase";

// signed storage link
// https://stackoverflow.com/questions/42956250/get-download-url-from-file-uploaded-with-cloud-functions-for-firebase#:~:text=Initialize%20the%20admin%20library%20and%20get%20a%20file%20reference%20as%20your%20normally%20would%3A


export default function PlayButton({
                                       pleaseLogin,
                                       term = "",
                                       meaning = "",
                                       wordType = "",
                                       audioTest = false
                                   }) {
    const history = useHistory();
    const {currentUser, userTransaction} = useAuth();
    const [playing, setPlaying] = useState(false);
    let [audio, setAudio] = useState(new Audio(testAudio, {disableDownload: true}));

    useEffect(() => {
        audio.addEventListener('ended', () => {
            setPlaying(false)

        });
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, [playing]);


    function playingAudio() {

        try {

            audio.load();
            audio.play();

            if (!audio.paused) {

                setPlaying(true);
            }

        } catch (error) {
            // console.log(error)
        }

    }

    function settingAudio(audio, callback) {
        setTimeout(function () {

            setAudio(audio)

            callback();
        });
    }

    const playOnlineSound = async () => {
        term = wordFormatToFile(term, meaning, wordType);
        try {
            let url = await storage.ref("word_audio_files").child(term + ".mp3").getDownloadURL()

            audio = new Audio(url, {disableDownload: true});

            settingAudio(audio, playingAudio)

        } catch (error) {
            // console.log(error)
        }

    }
    const playLocalSound = () => {

        try {

            audio = new Audio(testAudio, {disableDownload: true});

            settingAudio(audio, playingAudio)

        } catch (error) {
            // console.log(error)
        }
    }

    return (

        <div>
            {/* <audio ref="audio_tag" src="../assets/yekola_lingala.mp3" controls autoPlay /> */}
            <a style={{cursor: "pointer"}}>
                <span className="ms-3" style={{color: "#00a2ed"}} onClick={async () => {

                    // is logged In
                    if (currentUser) {

                        if (audioTest) {

                            playLocalSound();

                        } else if (hasValidSubscription(userTransaction.subscriptionExpirationDate)) {
                            playOnlineSound();
                        } else {
                            // console.log("NO SUBSCRIPTION")
                            history.push("/account");
                        }

                    } else {
                        pleaseLogin();
                    }
                }

                }> <FontAwesomeIcon icon={playing ? faVolumeUp : faPlayCircle} size={audioTest ? "6x" : "1x"}/></span>
            </a>

        </div>

    );
}
