import React from "react";
import "../styles/Word.css";
import {useHistory} from "react-router-dom";
import {getLanguage, t, translate} from "react-switch-lang";
import PlayButton from "./PlayButton";
import {constants} from "../Constants";


function Word({word, wordType, searchQuery, handleSearchQuery, pleaseLogin}) {

    let lingalaWord = word.ln;
    let equivalent = getLanguage() === "fr" ? word.fr : word.en;
    const isPaymentEnabled = JSON.parse(process.env.REACT_APP_IS_PAYMENT_ENABLED);
    const history = useHistory();

    const getLingalaFormattedWord = () => {
        if (t(wordType) === "verbe" || t(wordType) === "verb") {


            return (

                <h4 className="d-flex"><span className="badge text-bg"
                                             style={{backgroundColor: constants.colors.gray}}>         <a
                    style={{color: constants.colors.textGreen, fontWeight: "bold"}} onClick={() => {
                    handleSearchQuery(searchQuery);
                    history.push(`/conjugation/${lingalaWord}`, {wordType: word.wordType})
                }}
                >
                        {lingalaWord}
                    </a></span>

                    {isPaymentEnabled && <PlayButton pleaseLogin={pleaseLogin} term={lingalaWord} meaning={word.meaning}
                                                     wordType={wordType}/>}
                </h4>

            );
        } else {
            return (
                <h5 className="d-flex" style={{color: constants.colors.textGreen, fontWeight: "bold"}}>
                    {lingalaWord}
                    {isPaymentEnabled && <PlayButton pleaseLogin={pleaseLogin} term={lingalaWord} meaning={word.meaning}
                                                     wordType={wordType}/>}
                </h5>
            );
        }


    }

    const getSynonyms = () => {

        if (word.synonyms.length !== 0) {

            let content;

            if (t(wordType) === "verbe" || t(wordType) === "verb") {

                content =
                    word.synonyms.map((synonym) => (

                        <h4 className="d-flex" key={synonym.toString()}>
                            <span className="badge text-bg" style={{backgroundColor: constants.colors.gray}}>

                                <a style={{color: constants.colors.darkGray}} onClick={() => {
                                    handleSearchQuery(searchQuery);
                                    history.push(`/conjugation/${synonym}`, {wordType: word.wordType});
                                }}
                                >
                                    {synonym}
                                </a>
                            </span>

                            {isPaymentEnabled &&
                                <PlayButton pleaseLogin={pleaseLogin} term={synonym} meaning={word.meaning}
                                            wordType={wordType}/>}
                        </h4>


                    ));

            } else {
                content = word.synonyms.map((synonym) => (

                    <h5 className="d-flex" key={synonym.toString()}>
                        <span style={{color: constants.colors.darkGray}}>{synonym}</span>
                        {isPaymentEnabled && <PlayButton pleaseLogin={pleaseLogin} term={synonym} meaning={word.meaning}
                                                         wordType={wordType}/>}

                    </h5>


                ));
            }

            return (<div>
                <h6 className="text-muted">
                    <ins><i>{t("synonyms")}</i></ins>
                </h6>

                <div className="ms-5">
                    {content}
                </div>

            </div>);

        }

    }

    const getPlurals = () => {
        if (word.plurals.length !== 0) {
            return (
                <div>
                    <h6 className="text-muted">
                        <ins><i>{t("plurals")}</i></ins>
                    </h6>

                    <div className="ms-5">
                        {
                            word.plurals.map((plural) =>

                                <h5 className="d-flex" key={plural}><span
                                    style={{color: constants.colors.darkGray}}>{plural}</span>
                                    {isPaymentEnabled && <PlayButton pleaseLogin={pleaseLogin}
                                                                     term={plural}
                                                                     meaning={word.meaning}
                                                                     wordType={wordType}/>}
                                </h5>)
                        }

                    </div>

                </div>
            );
        }

    }


    return (
        <li className="list-group-item">
            <h6><span className="badge text-bg-secondary">{t(wordType)}</span></h6>
            <div className="wordContent">
                <h5 className="text-muted mb-1">{equivalent}</h5>

                <div className="ms-5 mb-3">{getLingalaFormattedWord()}</div>

                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">{getSynonyms()}</div>
                    <div className="col-lg-6 col-md-12 col-sm-12">{getPlurals()}</div>
                </div>


            </div>
        </li>

    );
}

export default translate(Word);
