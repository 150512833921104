import React from "react";
import androidLogo from "../assets/images/google-play-badge.png";
import iosLogo from "../assets/images/app-store-black.svg";
import feature from "../assets/images/phone_screenshot.png";
import {Helmet} from "react-helmet";
import {t} from "react-switch-lang";

function Download() {
    return (
        <div>

            {/*<Ad />*/}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("download")}</title>
                <meta name="description"
                      content={t("downloadDescription")}/>

                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>

            <div className="row justify-content-center my-2">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <a style={{textAlign: "center", display: "block"}}
                       href="https://play.google.com/store/apps/details?id=com.tsgtechnologies.lingala" target="_blank">
                        <img style={{width: "250px", height: "auto"}} src={androidLogo} alt="Android Download Button"/>
                    </a>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <a style={{textAlign: "center", display: "block", marginTop: 14}}
                       href="https://apps.apple.com/us/app/lingala/id1569540134?itsct=apps_box_badge&amp;itscg=30200"
                       target="_blank">
                        <img style={{width: "250px", height: 66}} src={iosLogo} alt="App Store Download Button"/>
                    </a>
                </div>

            </div>
            <br></br>
            <img style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "25px",
                border: "2px solid #515052"
            }} width="360px"
                 height="720px"
                 src={feature} alt="Phone"/>


            <br></br>
            <br></br>

        </div>
    );
}

export default Download;
