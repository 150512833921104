import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App"
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./services/AuthContext";
import {DataProvider} from "./services/DataContext";

ReactDOM.render(
    <BrowserRouter>
        <AuthProvider>
            <DataProvider>
                <App/>
            </DataProvider>
        </AuthProvider>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
